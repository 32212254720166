<template>
    <div class="login">

        <div class="c w-xxs mt-xxl">
            <div>
                <h3 class="color-accent">{{ $t('login.loginbox.title') }}</h3><br />
                <div v-html="$t('login.loginbox.text')"></div>
            </div>

            <div class="floating--card product">
                <div class="product--content">
                    <form id="login_form" v-show="mode != 'twofactor'" @submit.prevent="onSubmit">
                        <fieldset>
                            <label for="login_email">{{ $t('login.fields.email') }}</label>
                            <input type="email" autocomplete="email" v-model="values.email" required autofocus />
                        </fieldset>

                        <fieldset v-if="mode == 'login'">
                            <label for="login_password">{{ $t('login.fields.password') }}</label>
                            <input type="password" autocomplete="password" v-model="values.password" required />
                        </fieldset>

                        <div v-if="toast.msg" class="toast px-l py-r rounded-s" :class="toast.classes">
                            {{ toast.msg }}
                        </div>

                        <div v-if="mode == 'login'" class="text-center pt-l">
                            <button type="submit" class="w-12 mb-s bg-accent" :class="{ '--loading': loading }" form="login_form">
                                {{ $t('login.actions.login') }}
                            </button>
                            <button type="button" @click="change_mode('forgotpassword');" class="w-12 bg-black">{{ $t('login.actions.password_forgot') }}</button>
                            <br />
                        </div>
                        <div v-else-if="mode == 'forgotpassword'" clas="pt-l">
                            <button type="submit" class="w-12 mb-s bg-accent" :loading="loading">{{ $t('login.actions.password_reset') }}</button>
                            <button type="button" @click="change_mode('login');" class="w-12 bg-black" form="login_form">{{ $t('login.actions.login') }}</button>
                        </div>
                    </form>
                </div>
            </div>

        </div>

    </div>
</template>

<script>

export default {
    name: 'Login',
    data() {
        return {
            mode: 'login',
            values: {
                email: '',
                password: '',
                code: '',
                user: null,
            },
            toast: {
                msg: '',
                classes: '',
            }
        };
    },
    created() {
        this.init();
    },
    methods: {
        setToast({ msg, classes }) {
            this.toast.msg = msg;
            this.toast.classes = classes;

            setTimeout(() => {
                this.toast.msg = '';
                this.toast.classes = '';
            },4000);
        },

        init(path = this.$route.path) {
            if (path.startsWith('/loggedout')) {
                this.mode = 'loggedout';
            } else if (path.startsWith('/logout')) {
                this.$store.dispatch('Auth/logout');
                this.redirect('/loggedout', true);
            } else if (this.$route.query.token) {
                this.$store.dispatch('Auth/restoreSession', { tokens: [this.$route.query.token] }).then(() => {
                    this.redirect('/');
                    return false;
                }).catch((err) => {
                    this.setToast({ msg: this.$t('login.toasts.error'), classes: 'bg-danger-theme' });
                    setTimeout(() => { window.location.href = '/login/'; }, 1500);
                    console.error('login failed', err);
                });
            } else {
                this.mode = 'indeterminate';
                if (process.env.NODE_ENV !== 'development' && Array.isArray(this.authProviders) && this.authProviders.length && this.authProviders[0].type === 'saml') {
                    this.mode = 'saml';
                    this.$store.dispatch('Auth/loginWithProvider', { url: this.authProviders[0].login_url });
                } else {
                    this.mode = 'login';
                    this.$store.dispatch('Auth/SmartLockGet').then(({ id, password }) => {
                        this.values.email = id;
                        this.values.password = password;
                        this.submitLogin();
                    }).catch(() => {});
                }
            }
        },

        onSubmit() {
            if (this.loading) { return false; }
            switch (this.mode) {
            case 'login':
                this.submitLogin();
                break;

            case 'passreset':
                this.submitPassReset();
                break;

            case 'twofactor':
                this.submit2fa();
                break;

            default:
            }
            return true;
        },

        submitLogin() {
            this.$store.dispatch('Auth/login', { email: this.values.email, password: this.values.password })
                .then((r) => {
                    if (r.twofactor) {
                        this.mode = 'twofactor';
                        this.values.user = r.user;
                    } else {
                        this.$store.dispatch('Auth/SmartLockSave', { id: this.values.email, password: this.values.password });
                        this.redirect('/');
                    }
                }).catch(() => {
                    this.setToast({ msg: this.$t('login.toasts.error'), classes: 'bg-danger-theme' });
                });
        },

        submitPassReset() {
            this.$store.dispatch('Auth/passReset', { email: this.values.email })
                .then((r) => {
                    this.mode = 'login';
                    if (r.status === 'success') {
                        this.setToast({ msg: this.$t('login.toasts.pass_sent'), classes: '' });
                    }
                })
                .catch((e) => {
                    if (e.message && e.message === 'unsupported') {
                        this.setToast({ msg: this.$t('login.toasts.error_unsupported'), classes: 'bg-danger-theme' });
                    } else {
                        this.setToast({ msg: this.$t('login.toasts.pass_unknown'), classes: 'bg-danger-theme' });
                    }
                });
        },

        submit2fa() {
            this.$store.dispatch('Auth/loginTwoFactor', {
                // TODO do I need all these values?
                email: this.values.email, password: this.values.password, user: this.values.user, code: this.values.code,
            })
                .then(() => {
                    this.redirect('/');
                }).catch(() => {
                    this.setToast({ msg: this.$t('login.toasts.error_token'), classes: 'bg-danger-theme' });
                });
        },

        redirect(fallback, forceQuery) {
            if (!forceQuery && this.$route.query.redirect) {
                router.push(this.$route.query.redirect).catch(console.error);
            } else {
                router.push(fallback);
            }
        },
    },
    computed: {
        loading() {
            return this.$store.state.app.loading;
        },

        data() {
            return this.$store.getters.data;
        },
    },
    watch: {
        '$route.path': {
            handler: 'init',
        },
    },
};
</script>

<style>
.page_login #main {
    justify-content: center;
    align-items: center;
    display: flex;
    padding: var(--padding-xxl) 0;
}
.login .state { min-height: 0; }
.login {
    max-width: var(--productcolumn-width);
    width: 100%;
}
.login .product {
    padding: var(--padding-m);
}
#login_form { width: 100%; }
</style>
